import React from 'react'
import { toast } from 'react-toastify'
import Button from '../components/Button'
import DisplayTable from '../components/DisplayTable'
import { LoadingContainer } from '../components/Loading'
import Search from '../components/Search'
import { LOADING_STATE, MEMBER_LOOKUP_URL } from '../config'
import API from '../utils/API'
const Home = () => {
    const [loading, setLoading] = React.useState(false)
    const [memberName, setMemberName] = React.useState("")
    const [memberId, setMemberId] = React.useState("")
    const [state, setState] = React.useState(LOADING_STATE.INITIAL);
    const [barcode, setBarcode] = React.useState("")
    const [searchedBarcode, setSearchedBarcode] = React.useState("")
    const [error, setError] = React.useState("")

    const handleSearch = async () => {
        setSearchedBarcode(barcode);
        if (!barcode) {
            toast.error('Please enter a barcode')
            return
        }
        setLoading(true)
        setState(LOADING_STATE.LOADING)

        try {

            const response = await API.post(MEMBER_LOOKUP_URL, {
                barcodes: [barcode.trim()]
            })

            if (response.status === 200) {
                if (response.data[barcode]) {
                    setMemberName(response.data[barcode].name);
                    setMemberId(response.data[barcode].account_number);
                    setState(LOADING_STATE.LOADED)
                } else {
                    toast.error("Barcode not found")
                    setError("Barcode not found")
                    setState(LOADING_STATE.ERROR)
                }
            }
            else {
                console.log(response);
                toast.error("Error " + response.data)
                setError("Error " + response.data)
                setState(LOADING_STATE.ERROR)
            }
            setLoading(false)
        } catch (e) {
            toast.error("Error " + e)
            setError("Error " + e)
            setState(LOADING_STATE.ERROR)
            setLoading(false)
        }
    }
    return (
        <div className="flex justify-center items-center min-h-[40vh]">
            <div>

                <h1 className='text-center font-bold text-xl my-5'>Bar Code Lookup</h1>
                <div className="flex items-center justify-evenly">
                    <Search value={barcode} setValue={setBarcode} />
                    <Button handleSearch={handleSearch} loading={loading} />
                </div>


                {
                    state === LOADING_STATE.INITIAL ? null :

                        state === LOADING_STATE.LOADED ? <div className="displaytable">
                            <DisplayTable barCode={searchedBarcode} memberName={memberName} memberId={memberId} loaded={true} />
                        </div> : <div className='text-red-300 text-center my-5'>{error}</div>
                }

            </div>
        </div >
    )
}

export default Home