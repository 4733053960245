import { Link } from "react-router-dom";
import React from "react";

const NotFound = () => {
  return (
    <div className=" w-[100%] h-[90vh]  bg-gray-100 flex justify-center items-center flex-col">
      <h1 className="text-lg">Page Not found</h1>
      <h3>
        <Link to="/" className="text-green-500">
          Home
        </Link>
      </h3>
    </div>
  );
};

export default NotFound;
