import React from 'react'

const Search = ({ value, setValue }: {
    value: string, setValue: React.Dispatch<React.SetStateAction<string>>
}) => {
    const [inputValue, setInputValue] = React.useState(value)
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value)
        setValue(e.target.value)
    }
    return (
        <input className='block p-3   text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' type="text" placeholder='bar code' value={inputValue} onChange={(e) => { handleChange(e) }} />

    )
}

export default Search