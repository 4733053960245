import axios, { AxiosRequestConfig, ResponseType } from "axios";
// import { getTokenFromCookie } from "../utils/auth";
import { v4 as uuidv4 } from 'uuid';
import { isUndefined } from "lodash";


export default class API {
    static async get(URL: string, config?: { responseType?: ResponseType }) {
        let cancelToken: any;
        if (!isUndefined(cancelToken)) {
            cancelToken.cancel();
        }
        cancelToken = axios.CancelToken.source();

        const headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            // "Authorization": `Bearer ${getTokenFromCookie()}`,
            "x-correlation-id": uuidv4(),
            "x-client-name": "barcode-lookup-portal",
        };

        return axios.get(URL, {
            cancelToken: cancelToken.token,
            responseType: config?.responseType ? config.responseType : "json",
            ...config,
            headers,
        });
    }

    static async post(URL: string, data: any, config: AxiosRequestConfig = {}) {
        let cancelToken: any;
        if (!isUndefined(cancelToken)) {
            cancelToken.cancel();
        }

        const headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            // "Authorization": `Bearer ${getTokenFromCookie()}`,
            "x-correlation-id": uuidv4(),
            "x-client-name": "barcode-lookup-portal",
            ...config.headers
        };
        return axios.post(URL, data, {
            cancelToken: cancelToken ? cancelToken.token : "",
            ...config,
            headers,
        });
    }
}