import { env } from './env'

// for dev
// export const API_URL = 'http://localhost:3001/invoice-api';

// FOR PRODUCTION
export const API_URL = env.REACT_APP_INVOICE_API_BASE_URL;
export const MEMBER_LOOKUP_URL = `${API_URL}/member/lookup`;


export enum LOADING_STATE {
    INITIAL = '0',
    LOADING = '1',
    LOADED = '2',
    ERROR = '3',
}