import React from 'react'
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";


function TableHeader() {
    return (<thead>
        <tr>
            <th className="px-4 py-2 font-medium text-left text-gray-900 whitespace-nowrap">
                Barcode
            </th>
            <th className="px-4 py-2 font-medium text-left text-gray-900 whitespace-nowrap">
                Member Name
            </th>
            <th className="px-4 py-2 font-medium text-left text-gray-900 whitespace-nowrap">
                Member Id
            </th>
        </tr>
    </thead>);
}



function Body(props) {
    return (<tbody className="divide-y divide-gray-200">
        <tr>
            <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap">
                {props.barCode ? props.barCode : 'Barcode'}
            </td>
            <td className="px-4 py-2 text-gray-700 whitespace-nowrap">
                {props.memberName ? props.memberName : 'MemberName'}
            </td>
            <td className="px-4 py-2 text-gray-700 whitespace-nowrap">
                <div className="flex">

                    <div className="m-id">
                        {props.memberId ? props.memberId : 'MemberId'}
                    </div>

                    <button className='mx-3 text-blue-400' onClick={props.copyToClipboard}>copy</button>
                </div>


            </td>
        </tr>
    </tbody>);
}


const DisplayTable = ({ barCode, memberName, memberId, loaded }: { barCode?: string, memberName?: string, memberId?: string, loaded?: boolean }) => {

    if (!loaded) {
        return (
            <div>

            </div>
        )
    }
    const copyToClipboard = () => {
        let copyText = memberId ? memberId : "";
        copy(copyText);
        toast.success("Copied to clipboard");
    }

    return (
        <div className="overflow-x-auto mt-10">
            <table className="min-w-full text-sm divide-y-2 divide-gray-200">
                <TableHeader />

                <Body copyToClipboard={copyToClipboard} barCode={barCode} memberName={memberName} memberId={memberId} />
            </table>
        </div>

    )
}

export default DisplayTable